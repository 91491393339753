@import "root";

.auth-title {
  font-size: 58px;
  font-weight: 600;
}
.form-auth {
  height: 100%;

  .form-group {
    margin-bottom: 1.5rem;
    position: relative;
    .form-control {
      color: $light;
      &::placeholder {
        opacity: 1; /* Firefox */
        font-weight: 400;
        font-size: 14px;
      }
      &.form-control-lg {
        @include padding(16px 18px);
        border-radius: 12px;
      }
    }
    .input-group-addon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      bottom: auto;
    }
  }
  .btn-primary {
    color: white;
    &.btn-lg {
      font-size: 16px;
      border-radius: 10px;
    }
    &.btn-login {
      box-shadow: 2px 5px 16px 4px rgb(0 4 22 / 19%);
    }
    &.btn-primary-dark {
      background-color: rgba(18, 113, 230, 0.3);
    }
  }
  hr {
    border-top-color: #bec7f5;
  }
}
.img-side-table {
  position: absolute;
  left: -4rem;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}
.img-flower-pot {
  position: absolute;
  left: 0;
  bottom: 12.333333%;
  z-index: -1;
}

.card-auth {
  border-radius: 20px;
  border: 1px solid;
  // background: rgba(217, 217, 217, 0.06);
  border-color: rgba(255, 255, 255, 0.25);
  // backdrop-filter: blur(4px);
  position: relative;
  z-index: 1;
  overflow: hidden;
  max-width: 480px;
  margin: 0 auto;
  @include media-breakpoint-down(lg) {
    max-width: 450px;
  }
  @include media-breakpoint-down(md) {
    max-width: 350px;
  }
  .card-header {
    padding-top: 2rem;
    padding-bottom: 1rem;
    @include media-breakpoint-down(md) {
      padding-top: 1.5rem !important;
    }
    .card-title {
      font-size: 26px;
      justify-content: center;
      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }
    .card-lead-text {
      font-size: 16px;
      font-weight: $font-weight-normal;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }
  .card-body {
    padding: 1rem 3rem;
    // Tablet & mobile modes
    // @include media-breakpoint-down(lg) {
    //   padding: 1rem 2rem;
    // }
    // @include media-breakpoint-down(md) {
    //   padding: 1rem 1rem;
    // }
  }
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(217, 217, 217, 0.03);
    backdrop-filter: blur(8px);
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.auth-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    background: url(../../../assets/images/right.png) no-repeat left;
    padding: 0.15rem 2.5rem;
    margin-bottom: 0.25rem;
    font-size: 16px;
    font-weight: normal;
  }
}

@include color-mode(dark) {
  .card {
    &.card-auth {
      --#{$prefix}card-bg: rgba(217, 217, 217, 0.03);
      --#{$prefix}card-border-color: rgba(217, 217, 217, 0.06);
    }
    // Bordered style
    &.card-bordered {
      border-color: rgba(217, 217, 217, 0.06);
    }
  }
}
