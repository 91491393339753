// Custom.scss

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;1,700&display=swap');

@import './assets/fonts/fonts.css';
//@import './assets/fonticon/fonticon.css';

#root {
  display: contents;
}

// todo: move to file later - pending

.ticker-h-c-l-f {
  border-radius: 10px;
  border: 1px solid #c3c3c3;
  margin-bottom: 4rem;
}

.dark_theme .ticker-h-c-l-f {
  border: 1px solid #2f3038;
}

.ticker-h-c-l-f .ag-root-wrapper.ag-layout-normal {
  border-radius: 0px;
  border: none;
}

.dark_theme .ticker-h-c-l-f .ticker-t-h {
  color: #fff;
}

.ticker-h-c-l-f .ticker-t-h {
  color: #000;
}

.ticker-h-c-l-f .ticker-t-h {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 28px 20px;
}

.custom-table table,
th,
td {
  border: none;
  border-collapse: collapse;
}

.custom-table table tr {
  border-style: none;
  border-bottom: #fff;
}

.custom-table .table .thead-dark th {
  color: #11121f;
  white-space: nowrap;
  font: 400 0.75rem/1rem 'Poppins';
  background-color: #eeeef0;
}

.custom-table .table .thead-dark {
  border-radius: 4px;
}

.custom-table .table .thead-dark th img {
  margin-top: -5px;
}

.custom-table .table td,
.table th {
  border-top: none;
}

.custom-table .table thead th {
  border-bottom: none;
}

.custom-table .table thead th:last-child {
  font: 400 0.875rem/1rem 'Poppins';
  color: #1271e6;
}

.custom-table .table tbody .first-row td:nth-child(1) {
  background: url(./assets/images/un-rate.png) no-repeat left;
  padding-left: 20px;
}

.custom-table .table tbody .first-row.ratting-n td:nth-child(1) {
  background: none;
}

.custom-table {
  height: 125vh;
}

.custom-table .table tbody .ratting-row td:nth-child(1) {
  background: url(./assets/images/star.PNG) no-repeat left;
  padding-left: 24px;
}

.custom-table .table tbody .first-row td .inner-tx-g {
  color: #06893d;
  border-radius: 20px;
  border: 1px solid #06893d;
  background: #d6eadf;
  text-align: center;
}

.custom-table .table tbody .first-row td .inner-tx-b {
  color: #1271e6;
  border-radius: 20px;
  border: 1px solid #afcdf3;
  background: #d6e4f6;
  text-align: center;
  padding: 0 12px;
}

.custom-table .table tbody .first-row td .inner-tx-r {
  color: #db0000;
  border-radius: 20px;
  border: 1px solid #eb8080;
  background: #f5d5d5;
  text-align: center;
  padding: 0 12px;
}

.custom-table .table tbody .first-row td:nth-child(7)span {
  color: #1271e6;
}

.custom-table .table tbody .first-row td {
  white-space: nowrap;
  font: 400 0.75rem/1rem 'Poppins';
  color: #11121f;
  vertical-align: middle;
}

.bg-row {
  background: rgba(17, 18, 31, 0.05);
}

.custom-table .table tbody .first-row td .inner-tx-gray {
  text-align: center;
  color: #41414c;
  background: #efeff0;
  border: 1px solid #a7a7a7;
  border-radius: 20px;
}

.bg-row td:nth-child(9) {
  color: #7f8087;
}

.dark_theme .custom-m {
  margin: 1rem 0;
  border-radius: 10px;
}

.bttm-border h5 {
  border-bottom: 1px solid #56656e;
  padding: 0.4rem 0.8rem;
}

.custom-m table {
  width: 100%;
  background: #292c3d;
  border-radius: 0 0 10px 10px;
}

.bg-lite .custom-m table {
  background: #fff;
}

.custom-m table tr {
  border-bottom: 1px solid #3b3d4b;
}

.custom-m table tr td:first-child {
  font: 400 0.875rem/1rem 'Poppins';
  color: #fff;
  width: 50%;
}

.bg-lite .custom-m table tr td:first-child,
.bg-lite .custom-m table tr td:last-child {
  color: #292c3d;
}

.custom-m table tr td:last-child {
  font: 700 0.875rem/1rem 'Poppins';
  color: #fff;
  width: 50%;
  text-align: right;
}

.dark_theme .inner-main-d.custom-m .bttm-border h5 {
  color: #fff;
}

.custom-m .bttm-border table tr td img:last-child {
  border-radius: 30px;
  width: auto;
  background: none;
  padding: 0;
  border-radius: 30px;
  margin: 0;
}

.custom-m table tr td span {
  color: #1271e6;
}

.custom-m table tr td {
  padding: 0.8rem;
}

.custom-m table tr:last-child {
  border-bottom: none;
}

.dark_theme .inner-main-d.custom-m h5 {
  padding: 0.4rem 1rem;
  font: 700 0.875rem/1rem 'Poppins';
}

.dark_theme .custom-m .bttm-border table {
  background: #11121f;
}

.custom-m .bttm-border table {
  background: #fff;
}

.custom-m .bttm-border table tr td {
  color: #11121f;
}

.custom-m .bttm-border table tr td {
  color: #11121f;
}

.dark_theme .custom-m .bttm-border table tr td {
  color: #fff;
}

.w-100 {
  width: 100%;
}

.z-9999 {
  z-index: 9999;
}

.range-filter {
  overflow-y: visible;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__view-calendar-icon {
  display: flex !important;
  align-items: center;
  flex-direction: row-reverse;
}

// to hide the input type number arrows
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// chat styles #############################
//  START
.chat-window {
  height: 100vh !important;
}

.chat-window .modal-content {
  height: 80vh !important;
}

.quries-body {
  height: 60vh;
  overflow: scroll;
}

.chat-query button {
  margin-left: 5px;
}

.quries-body ul li {
  margin-bottom: 25px;
}

.hit-text {
  font-size: 11px;
  margin: 10px;
}

.dark_theme .hit-text {
  color: #fff;
}

// .flow_modal .modal-dialog{
// top:7vh
// }
.heatmap-height {
  height: 80vh !important;
}

.base-blue {
  color: var(--bs-primary);
}

.loading-dots::after {
  content: " ";
  display: inline-block;
  width: 1ch;
  animation: dots 1s steps(5, end) infinite;

}

@keyframes dots {

  0%,
  20% {
    content: '.';
  }

  40% {
    content: '..';
  }

  60% {
    content: '...';
  }

  100% {
    content: '';
  }
}

.pointer {
  cursor: pointer;
}

.form-check {
  .form-check-input {
    cursor: pointer;
  }

  .form-check-label {
    cursor: pointer;
    width: 100%;
  }
}

//  END
// chat styles #############################

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
//$enable-rounded: true;
//$enable-shadows: true;
$enable-negative-margins: true;
$enable-smooth-scroll: false;

//
// Theme style
//

// Initialize
@import './styles/init';

// Components

@import './styles/components/components';
@import './styles/layout/layout';
@import './styles/pages/pages';