//
// Main
//
html{
  scroll-behavior: smooth;
}

html, body {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  font-family: $font-family-sans-serif;
  background-color: transparent;
  background-image: $page-bg-img-dark;
  background-size: 100% 100%;
  background-repeat: repeat-y;
  padding: 0;
  margin: 0;
}
$color-mode-type: data;

@include color-mode(dark) {
  body {
    background-image: $page-bg-img-dark;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
@include color-mode(light) {
  body {
    background-image: $page-bg-img;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}


// Desktop Mode
@include media-breakpoint-up(lg) {
  .mob {
    display: none;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {

  //
  .desk {
    display: none;
  }
}


