@import 'root';

.profile-nav {
  .nav-link {
    color: $gray-500;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1.5rem 0.5rem;
    justify-content: center;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(xl) {
      padding: 2rem 1rem;
      justify-content: start;
    }

    @include media-breakpoint-up(sm) {
      padding: 1.5rem 1rem;
    }

    @include media-breakpoint-down(md) {
      .link-text {
        font-size: 14px;
      }
    }

    >i {
      color: inherit;
    }

    .fa-angle-right {
      opacity: 0;
    }

    &.active {
      color: white;

      @include media-breakpoint-up(xl) {
        border-right-color: transparent !important;
      }

      @include media-breakpoint-down(xl) {
        border-bottom-color: transparent !important;
      }

      .fa-angle-right {
        opacity: 1;
      }

    }
  }

  .nav-item:last-child {
    >.nav-link {
      @include media-breakpoint-down(xl) {
        border-right: 0;
      }
    }
  }
}


.form-check.form-check-success .form-check-input:checked {
  border-color: $success;
}

// chip - styles
.chip-outer-wrapper {
  width: fit-content;
  background: $primary;
  color: $white;
  @include padding(5px);
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 8px;

  .chip-remove {
    color: $dark;
    background: $white;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 1;
    margin-left: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chip-text {
    @include margin(0 6px)
  }
}

.filter-wrapper {
  // width: 80vh;
  height: auto;
  padding: 20px;

  .form-group {
    margin-bottom: 1.2rem;
  }
}

.subscription-gaurd-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;

  .img-subscription-gaurd {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 3;
  }

  &.bg-blur {
    background: rgba(217, 217, 217, 0.03);
    backdrop-filter: blur(8px);
  }

  .subscription-gaurd-container-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(217, 217, 217, 0.03);
    backdrop-filter: blur(8px);
    left: 0;
    top: 0;
    z-index: 10;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;

  }

}


@include color-mode(dark) {
  .profile-nav {
    .nav-link {
      &.active {
        color: white;
      }
    }
  }
}

@include color-mode(light) {
  .profile-nav {
    .nav-link {
      border-right-color: rgba(17, 18, 31, 0.2);
      border-bottom-color: rgba(17, 18, 31, 0.2);

      &.active {
        color: $primary;
      }
    }
  }
}


// Desktop Mode
@include media-breakpoint-up(lg) {}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {}