.navbar-brand{
    display: flex;
    text-align: left;
    &:hover{
      cursor: pointer;
    }  
}
.logo{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .logo-icon{
        margin-right: 1rem;
        max-width: 40px;
        display: block;
        margin: 0 auto;
        .header-dashboard &{
          max-width: 36px;
        }
    }
    .logo-text{
        font-weight: 700;
        font-size: 20px;
        font-family: $font-family-logo;
        text-wrap: nowrap;
        .header-dashboard &{
          font-size: 16px;
        }
      }
    &.logo-horizontal{

        .logo-icon{
            margin-right: 0.75rem;
        }
    }
}
  
  @include color-mode(light) {
    .logo-text{
      color: $black;
    }
  }
  @include color-mode(dark) {
  }