body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-card {
  height: calc(100vh - 145px) !important;
  overflow: hidden;
}

.cell-bookmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ag-theme-quartz-dark {
  --ag-background-color: transparent !important;
  /* position: relative; */
  /* --ag-header-background-color:transparent !important */
}

.ag-side-bar {
  --ag-control-panel-background-color: transparent !important;
  border-left: 1px solid var(--ag-border-color);
  position: absolute !important;
  top: 50px;
  right: 0;
  height: calc(100% - 50px);
}

.ag-virtual-list-item {
  position: relative !important;
}

.ag-virtual-list-container {
  overflow: auto !important;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: var(--bs-scrollbar-color) transparent;
}

.ag-virtual-list-viewport {
  scrollbar-width: thin;
  scrollbar-color: var(--bs-scrollbar-color) transparent;
}

.ag-tool-panel-wrapper {
  background-color: var(--ag-header-background-color) !important;
}

.ag-virtual-list-viewport {
  padding: calc(var(--ag-widget-container-vertical-padding) * 1) !important;
}

.card {
  overflow: hidden;
}

.ag-popup {
  --ag-background-color: color-mix(in srgb, #fff, #182230 97%) !important;
  /* --ag-header-background-color:transparent !important */
}

/* .ag-theme-quartz-dark .ag-body{
  --ag-background-color: transparent !important;
  --ag-header-background-color:transparent !important
} */

.ag-theme-quartz-dark .ag-header {
  backdrop-filter: blur(10px);
  background: #ffffff0d;
  border: 0;
  border-radius: 4px;
}

.live-flow-button:hover {
  background-color: var(--ag-border-color);
}

.live-flow-button {
  font-weight: 600;
  font-size: 0.625rem;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 70px;
  display: flex;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

/* Different background colors for active/inactive states */
/* .live-flow-button.active {
  background-color: var(--success-bg);
}

.live-flow-button.inactive {
  background-color: var(--danger-bg);
}

.toolbar-button-icon-ctn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--toolbar-button-icon);
  color: var(--toolbar-button-icon);
  padding-bottom: 0.3rem;
}

.live-flow-active {
  color: var(--success);
}

.live-flow-inactive {
  color: var(--danger);
} */

.live-flow-button {
  font-weight: 600;
  font-size: 0.625rem;
  font-size: 0.625rem;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 70px;
  display: flex;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.toolbar-button-icon-ctn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--toolbar-button-icon);
  color: var(--toolbar-button-icon);
  padding-bottom: 0.3rem;
}

.live-flow-active {
  color: green;
}

.live-flow-inactive {
  color: red;
}

@media (max-width: 992px) {
  .app-card .card-bordered {
    border-radius: 0 !important;
  }
}

.z-10 {
  z-index: 10;
}

.rounded-100 {
  border-radius: 6rem !important;
}

.row-small {
  row-gap: 0.8rem;
}

.row-small > * {
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}

.text-truncate-container {
  width: 100%;
}

.text-truncate-container h5 {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-container .single-line {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-container p {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ag-apply-button {
  padding: 0.2rem 0.8rem !important;
  background-color: aquamarine;
  border-radius: 50px !important;
  background: rgba(0, 0, 0, 0.1) !important;
  border-color: rgb(18, 113, 230) !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(18, 113, 230);
}

.nav-tooltip {
  z-index: 9999;
  font-size: 0.8rem !important;
}

.min-width-20 {
  min-width: 20px;
}

.toggle-button {
  cursor: pointer;
}

.toggle-button.left {
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 0;
}

.toggle-button.right {
  border-top-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 6px;
}
.toggle-button.center {
  border: 0;
}

.toggle-button.active {
  background: #1470e6;
}

/* 

.toggle-buttons-horizontal {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.toggle-buttons-border {}
.toggle-button {
  border: 1px solid hsla(0, 0%, 100%, .2);
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--base-text-color);
  text-transform: uppercase;
  font-weight: 400;
  padding-top: .25rem;
  padding-bottom: .25rem;
  background-color: hsla(0, 0%, 100%, .2);
  justify-content: center;
  padding-left: .5rem !important;
  padding-right: .5rem !important;
  font-size: 13px;
  min-width: max-content;
}

.toggle-buttons-first {
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 0;
}

.toggle-buttons-last {
  border-top-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 6px;
} */

.calendar__wrapper {
  overflow: auto;
  margin-top: 0;
  inset: 0;
  height: 100%;
}

.calendar__ctn {
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.calendar-col {
  width: 100%;
  min-width: 250px;
  border-right: 1px solid var(--bs-gray-300);
}

.calendar__header {
  position: sticky;
  top: 0;
  background-color: var(--bs-primary-light);
  z-index: 2;
  padding: 0 1rem;
  border-bottom: 1px solid var(--bs-gray-300);
}

.calendar__date-ctn {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--bs-gray-300);
}

.calendar__date {
  text-transform: capitalize;
  font-size: 1rem;
  white-space: nowrap;
}

.calendar__captions {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem 1rem;
}

.caption {
  font-size: 0.85rem;
  padding: 0.5rem 0;
  font-weight: 500;
  text-transform: uppercase;
}

.place-self-center {
  place-self: center;
}

.calendar__data {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  height: 71.8vh;
  overflow: scroll;
}

.calendar__data .calendar__ticker-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  grid-auto-flow: column;
}

.earnings-list {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.avatar_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  width: 1.8rem;
  min-width: 1.8rem;
  height: 1.8rem;
  /* background-color: white */
}

.avatar_avatar-img {
  width: 1.8rem;
  min-width: 1.8rem;
  height: 1.8rem;
  object-fit: cover;
  font-size: 18px;
  line-height: 1.8rem;
}

/* oi cards */

.oi-card-item {
  border-color: #0c0e13 !important;
  border-width: 1px !important;
}

.oi-card-default {
  background-color: #414554;
}

.oi-card-red-3 {
  background-color: #f53538 !important;
}

.oi-card-red-2 {
  background-color: #bf4045 !important;
}

.oi-card-red-1 {
  background-color: #8b444e !important;
}

.oi-card-green-3 {
  background-color: #2fc557 !important;
}

.oi-card-green-2 {
  background-color: #2f9e4e !important;
}

.oi-card-green-1 {
  background-color: #35764d !important;
}

.oi-card-item-warp {
  color: white;
  border-width: 1px;
  height: 100%;
}

.oi-card-item-warp:hover {
  border-color: #1470e6;
}

.oi-card {
  display: grid;
  gap: 3px;
  grid-template-columns: repeat(8, minmax(0px, 1fr));
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.fs-14 {
  font-size: 1.4rem;
}

.fs-11 {
  font-size: 1.1rem;
}

.fw-bold {
  font-weight: 700;
}

.fw-semibold {
  font-weight: 600 !important;
}

.font-semibold {
  font-weight: 600;
}

.text-xs {
  font-size: 1rem;
}

.text-muted-s {
  color: rgb(209 213 219 / 1) !important;
  font-size: 0.87rem;
}

.min-w-100px {
  min-width: 200px;
  width: 200px;
  max-width: 200px;
}

.height-41px {
  height: 41px;
}

.expiration-select {
  width: 100%;
  height: 100%;
  border: 0;
  text-align: center;
}
.ag-header-cell-center-strike .ag-header-cell-label {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tickerDetails {
  height: 70vh !important; 
  min-height: 500px;
  max-height: 850px;
}