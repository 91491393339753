@include color-mode(light) {
  header {
    border-bottom: 1px solid rgba(218, 218, 218, 1);
  }
  .navbar {
    border-bottom: 1px solid rgba(218, 218, 218, 1);
  }
}
@include color-mode(dark) {
  .navbar {
    border-bottom: 1px solid rgba(218, 218, 218, 0.25);
  }
}

//
// Header
//
header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: get($header-config, desktop, default, height);
  display: flex;
  align-items: center;
  width: 100%;
  &.header-dashboard {
    height: get($header-config, desktop, dashboard, height);
    @include media-breakpoint-down(xl) {
      height: get($header-config, small-desktop, default, height);
    }
  }
  &.premium-header-dashboard {
    height: get($header-config, desktop, premium-dashboard, height);
    @include media-breakpoint-down(xl) {
      height: get($header-config, small-desktop, default, height);
    }
  }
  .premium-banner {
    background-color: rgba(179, 171, 255, 0.05);
    text-align: center;
  }
}

//Mobile Menu

.dropdown-profile {
  .dropdown-toggle {
    font-weight: $font-weight-medium;
    font-size: 16px;
    &.show,
    &:active,
    &:focus {
      border-color: transparent;
    }
    &::after {
      display: none;
    }
  }
  .fa-chevron-down {
    color: $primary;
    margin-left: 0.6rem;
  }
}

.mobile-user-dropdown-toggle {
  &::after {
    display: none;
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    // Page title
    .page-title {
      display: none !important;
    }
  }
}

//Sidenbar

.dashboard .menu-scroll {
  height: 100%;
}
.menu-side-icon {
  border-right: none;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  > a {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}

//Navbar Landing Page
.navbar-landing {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  width: 100%;
  &.is-sticky {
    background-color: rgba($dark, 0.8);
  }
  .navbar-nav {
    .nav-link {
      position: relative;
      font-size: 18px;
      &::before {
        content: "";
        height: 4px;
        width: 0;
        background-color: transparent;
        position: absolute;
        pointer-events: none;
        left: 0;
        bottom: 0;
      }
      &.active,
      &:hover {
        text-decoration: none;
        &::before {
          background-color: $primary;
          width: 30px;
          transition: width 0.25s 0.25s ease;
        }
      }
    }
  }
}

//Topbar Live Flow
.btn-live-flow {
  font-size: 14px;
  min-width: 104px;
  .icon-play-flow {
    background-color: rgba(255, 255, 255, 0.16);
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.1) !important;
    height: 24px !important;
    width: 24px !important;
    font-size: 14px;
  }
  &.disabled {
    //opacity: 0.65;
  }
}

// used in top gainer loser
.sticky-header {
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  padding: 10px;
  z-index: 1; /* Ensure it's above other elements */
}

@include color-mode(light) {
  .navbar-landing {
    border-color: rgba(0, 0, 0, 0.1);
    &.is-sticky {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  .btn-live-flow {
    .icon-play-flow {
      background-color: #d3e7ff;
      box-shadow: 0 0 0 6px #d3e7ff !important;
      color: $dark;
      box-shadow: none;
    }
  }
}
@include color-mode(dark) {
}
