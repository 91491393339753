// pricing styles
.upgrade-pricing .dark_theme h1 {
  color: #fff;
}

.upgrade-pricing .dark_theme p {
  color: #fff;
}

.upgrade-pricing .dark_theme h5 {
  color: #fff;
}

.upgrade-pricing .dark_theme h2 {
  color: #fff;
}

.upgrade-pricing .dark_theme .premium-bx ul li {
  color: #fff;
}

.upgrade-pricing.bg-lite h3 {
  color: #11121f;
}

.upgrade-pricing.bg-lite p {
  color: #11121f;
}

.upgrade-pricing.bg-lite h5 {
  color: #11121f;
}

.upgrade-pricing.bg-lite h2 {
  color: #11121f;
}

.upgrade-pricing.bg-lite .premium-bx ul li {
  color: #11121f;
}

.upgrade-pricing.bg-lite .premium-bx {
  background-color: #fff !important;
}

.upgrade-pricing.bg-lite .carousel-indicators [data-bs-target] {
  background-color: #11121f !important;
}

.premium-bx h5 {
  font: 700 1rem/1.4rem 'Poppins';
  color: #11121f;
}

.premium-bx h2 {
  font: 700 2.25rem/2.6rem 'Poppins';
  color: #11121f;
}

.premium-bx p {
  font: 400 0.75rem/1rem 'Poppins';
  color: #11121f;
}

.premium-bx button {
  font: 500 0.875rem/1rem 'Poppins';
  color: #1271e6;
  border: 1px solid #1271e6;
  width: 100%;
  background: none;
  border-radius: 6px;
  padding: 0.69rem 0;
  margin: 0 0 1.6rem;
  cursor: pointer;
}

.premium-bx button:hover {
  background: #1271e6;
  color: #fff;
}

.premium-bx:hover {
  border: 1px solid #dadada;
  border-radius: 30px;
  cursor: pointer;
}

.premium-bx ul li {
  font: 500 0.875rem/1rem 'Poppins';
  color: #11121f;
  padding: 0 0 0 1.125rem;
  line-height: 1.7rem;
}

.premium-bx ul li.right {
  background: url(../../../assets/images/right-1.png) no-repeat left;
}

.premium-bx ul li.cancel {
  background: url(../../../assets/images/cancel.png) no-repeat left;
  color: #87888f;
}

.premium-bx ul li.discord {
  background: url(../../../assets/images/discard.png) no-repeat left;
}

.premium-bx {
  width: 30%;
  padding: 2rem;
  border: 1px solid #ffffff1c;
  margin: 0 0 7rem;
  border-radius: 30px;
}

.premium-bx .recommended-btn {
  font: 500 0.5rem/1rem 'Poppins';
  color: #1271e6;
  background: #cadef7;
  width: auto;
  padding: 0.3rem 0.6rem;
  border: none;
}
