.faq-accordion{
    &.accordion{
        .accordion-item{
            background-color: transparent;
            border-width: 0 0 1px 0;
            border-radius: 0 !important;
            border-color: rgba($white, 0.3);
            .accordion-button{
                background-color: transparent;
            }
        }
    }
}