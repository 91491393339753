// ToggleSwitch.scss
$paused: rgb(219, 0, 0);
$play: rgb(6, 137, 61);

$paused-icon: rgb(219, 0, 0);
$play-icon: rgb(6, 137, 61);


.toggle-switch-container {
  display: flex;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $paused;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      content: "";
      position: absolute;
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: #eee;
      // background-color: #f97777;
      transition: 0.4s;
      border-radius: 50%;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 25%;
      transform: translate(-50%, -50%);
      color: $paused-icon; // Initial color when unchecked
      transition: color 0.4s; // Transition color change
      transition: transform 0.3s linear;
    }
  }

  &.checked .toggle-slider:before {
    background-color: #f1f1f1;
  }

  input:checked + .toggle-slider {
    background-color: $play;
    // background-color: #ee5365;

    svg {
      color: $play-icon; // Color when checked
      position: absolute;
      top: 50%;
      transform: translate(235%, -50%);
    }
  }

  input:focus + .toggle-slider {
    box-shadow: 0 0 1px $play;
  }

  input:checked + .toggle-slider:before {
    transform: translateX(23px);
  }
}
