.toast {
  .toast-header {
    .btn-close {
      margin-right: 0;
    }
  }
}
@if $enable-dark-mode {
  @include color-mode(dark) {
    .toast {
      --#{$prefix}toast-bg: #{$toast-background-color-dark};
      --#{$prefix}toast-header-bg: #{$toast-header-background-color-dark};
      --#{$prefix}toast-header-border-color: #{$toast-header-border-color-dark};
    }
  }
}

// Variables
$toastBorder: 2px solid;
$borderRadius: 10px;
$closeIconColor: #333333;
$closeIconSize: 15px;
$closeIconFontWeight: 700;
$toastSpacing: 12px;
$toastTop: 6px;
$closeIconLeft: 265px;
$closeIconPaddingTop: 14px;

// Mixins
@mixin toast-style($borderColor, $bgColor, $beforeContent, $afterContent) {
  border: #{$toastBorder} $borderColor !important;
  background: $bgColor !important;
  border-radius: $borderRadius !important;
  backdrop-filter: blur(10px) !important;

  &::before {
    // content: url("../assets/images/svg/#{$beforeContent}.svg"); // Your svg Path
    position: relative;
    z-index: 100000;
    left: $toastSpacing;
    top: $toastTop;
  }

  &::after {
    // content: url("../assets/images/svg/#{$afterContent}.svg"); // Your svg Path
    position: absolute;
    color: $closeIconColor;
    font-size: $closeIconSize;
    font-weight: $closeIconFontWeight;
    left: $closeIconLeft;
    padding-top: $closeIconPaddingTop !important;
  }
}

// Toast Styles
.Toastify__toast--warning {
  @include toast-style(#dbaf00, #dbaf0053, errorToast, closeToast);
}

.Toastify__toast--success {
  @include toast-style(#06893d, #06893d4c, successToast, closeToast);
}

.Toastify__toast--error {
  @include toast-style(#DB0000, #db000053, warnToast, closeToast);
}
.Toastify__toast--info {
  @include toast-style(#1271E6, rgba(18, 113, 230, 0.40), warnToast, closeToast);
}

.Toastify__toast-body {
  color: #fff;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
}

.Toastify__toast > button > svg {
  display: none;
}
