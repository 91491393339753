.app-aside {
  // position: fixed;
  // top: get($header-config, desktop, default, height);
  // left: 0;
  // bottom: 0;
  border-right: 1px solid;
  position: relative;
  max-height: calc(100vh - get($header-config, desktop, dashboard, height));
  height: calc(100vh - get($header-config, desktop, dashboard, height));
  z-index: get($aside-config, z-index);
  .aside-toggle {
    position: absolute;
    top: 1rem;
    right: -10px;
    z-index: 101;
    font-size: 10px;
    box-shadow: 0px 4px 4px rgba($dark, 0.7) !important;
  }
  @include media-breakpoint-down(xl) {
    max-height: calc(
      100vh - get($header-config, small-desktop, default, height)
    );
    height: calc(100vh - get($header-config, small-desktop, default, height));
  }
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.side-nav {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  width: 100%;
  .nav {
    .nav-link {
      width: 100%;
      color: $light;
      text-decoration: none;
      font-weight: normal;
      opacity: 0.25;
      padding: 0 1rem;
      margin-bottom: 2.5rem;
      .menu-expanded & {
         margin-bottom: 1rem;
       }

       @include media-breakpoint-up(xxxl) {
         margin-bottom: 3rem;
       }

      .menu-expanded & {
        @include media-breakpoint-up(xl) {
         // margin-bottom: 1.25rem;
        }
        @include media-breakpoint-up(xxl) {
          margin-bottom: 1.25rem;
         }
        @include media-breakpoint-up(xxxl) {
          margin-bottom: 2rem;
        }
      }

      position: relative;
      text-align: center;
      font-weight: $font-weight-medium;
     
      >img {
        max-width: 1px;
        max-height: 1px;

        @include media-breakpoint-up(xl) {
          max-width: 1.2px;
          max-height: 1.2px;
        }

        @include media-breakpoint-up(xxl) {
          max-width: 1.4rem;
          max-height: 1.4rem;
        }
      }
      &::before {
        content: "";
        width: 4px;
        background-color: $primary;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        position: absolute;
        left: 0;
        z-index: 100;
        height: 100%;
        top: 0;
        opacity: 0;
      }
      &.active,
      &:hover {
        opacity: 1;
        cursor: pointer;
        // background-color: rgba(18, 113, 230, 0.1);
        &::before {
          opacity: 1;
        }
      }
      > .link-text {
        width: 100%;
        display: none;
        text-align: center;
        font-size: 12px;
        margin-top: 0.25rem;
        .menu-expanded & {
          display: block;
        }
      }
    }
  }

  .nav-tooltip {
    z-index: 9999;
  }
}

.side-nav-secondary {
  position: absolute;
  bottom: 0;
  left: auto;
  right: auto;
}

.theme-toggle {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 66px;
  .offcanvas-header &{
    flex-direction: row;
    justify-content: end;
    width: auto;
  }
  @include media-breakpoint-down(xl) {
    min-height: 55px;
  }
  margin-top: 0;
  .form-switch {
    transform: rotate(90deg);
    .offcanvas-header &{
      transform: rotate(0);
    }
    &.form-check-solid {
      .form-check-input {
        height: 34px;
        width: 64px;
        background-image: url("../../assets/images/icons/icon-moon-circle-dark.svg");
        background-size: 28px;
        background-position: 31px center;
        border: 1px solid rgba(255, 255, 255, 0.15);
        @include media-breakpoint-down(xl) {
          height: 30px;
          width: 52px;
          background-size: 24px;
          background-position: 24px center;
        }
        &:checked {
          background-color: rgba(68, 97, 242, 0.25);
        }
        &:not(:checked) {
          background-color: rgba(18, 113, 230, 0.2);
          background-image: url("../../assets/images/icons/icon-sun-circle-light.svg");
          background-size: 28px;
          background-position: 2px center;
          @include media-breakpoint-down(xl) {
            background-size: 24px;
            background-position: 2px center;
          }
        }
      }
    }
    .nav-tooltip {
      transform: rotate(-90deg);
    }
    .form-check-label {
      position: absolute;
      left: -45px;
      bottom: 92px;
      width: 138px;
      transform: rotate(-90deg);
      background: $dark;
      padding: 4px 8px;
      opacity: 0;
      pointer-events: none;
      z-index: 1000;
      font-weight: $font-weight-medium;
      color: white;
      border-radius: 3px;
    }
    &:hover {
      .form-check-label {
        opacity: 1;
      }
    }
  }
}

.nav-mobile {
  .nav-link {
    display: flex;
    flex: 1;
    padding: 1rem 0;
    font-weight: $font-weight-medium;
    .link-icon {
      margin-right: 1rem;
      max-width: 16px;
    }
    .link-text {
      flex-grow: 1;
    }
  }
}

.navbar-toggler {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  border-color: $gray-900;
}

.offcanvas-header {
  justify-content: space-between;
  &.dashboard {
    flex-direction: row-reverse;
  }
  .btn-close{
    border-radius: 50%;
    height: 34px;
    width: 34px;
    border-width: 1px;
    border-style: solid;
    border-color: $gray-800;
    color: $gray-900;
    padding: 0;
    margin: 0;
  }
}

[data-bs-theme="dark"] {
  --icon-color-filter: brightness(1.5) sepia(1) saturate(87) hue-rotate(321deg);
}

[data-bs-theme="light"] {
  --icon-color-filter: brightness(1.5) sepia(1) saturate(29) hue-rotate(12deg);
}

.navigation-container {
  touch-action: pan-x pan-y;
  /* width: 100%; */
  overflow: auto;
  height: 100%;
  user-select: none !important;
  h2 {
    display: none;
  }
  .nav-link {
    justify-content: center;
    .side-right {
      display: none;
    }
    &.active {
      img {
        filter: var(--icon-color-filter)
      }
    }
    &.skip-active {
      img {
        filter: none
      }
    }
  }
  .expanded-section {
    align-items: center;
    justify-content: center;
    .nav-item {

    }
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.menu-expanded {
  .nav-link {
    justify-content: space-between;
    .side-right {
      display: block;
    }
  }

  h2 {
    font-size: 1rem;
    margin: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: var(--base-text-color);
    font-weight: 400;
    white-space: nowrap;
  }

  
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .navigation-container {
    .menu-expanded {
      img {
        width: get($aside-config, icon, desktop);
        height: get($aside-config, icon, desktop);
      }
    }
  } 
    
  .app-aside {
    transition: width get($aside-config, transition-speed) ease;
    width: get($aside-config, width, desktop);
    min-width: get($aside-config, width, desktop);

    // Fixed aside mode
    .aside-fixed & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
    }

    // Static aside mode
    .aside-static & {
      position: relative;
      z-index: 1;
    }

    // Aside secondary enabled
    .aside-secondary-enabled & {
      width: get($aside-config, width, desktop);
    }

    // Aside secondary disabled
    .aside-secondary-disabled & {
      width: get($aside-config, width, desktop);

      .aside-primary {
        border-right: 0;
      }
    }

    // Minimized aside mode
    &.menu-expanded {
      width: get($aside-config, expended-width, desktop);
      transition: width get($aside-config, transition-speed) ease;
    }
  }

  [dir="rtl"] {
    .aside-toggle {
      .svg-icon {
        transform: rotateZ(180deg);
      }

      &.active .svg-icon {
        transform: rotateZ(0deg) !important;
      }
    }
  }
}
