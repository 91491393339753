@import 'root';

.section-landing {
    position: relative;
    height: max-content;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background-color: transparent;
      backdrop-filter: blur(35px);
    }
    > .container{
      margin-top: get($header-config, desktop, default, height);
      margin-bottom: get($header-config, desktop, default, height);
    }
    .section-title{
      font-size: 42px;
      font-weight: $font-weight-bolder;
      margin-bottom: 2rem;
    }
    .lead{
      font-size: 20px;
      margin-top: 1.5rem;
    }
    .hero-img-container{
      box-shadow: 0 -25px 150px 0px rgba($primary, 0.65);
      border-radius: 12px;
      max-width: 1400px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        overflow: hidden;
        top: auto;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 33%;
        background: linear-gradient(#0000001a, #000000);
        filter: blur(6px);
        z-index: 1;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      > img{
        width: 100%;
        border-radius: 12px;
      }
    }
    .hero-img-shadow{
      box-shadow: 0 -25px 170px 0px rgba($primary, 0.65);
    }
    .img-shadow{
      box-shadow: 0 -5px 50px 0px rgba($primary, 0.55);
    }
    .img-shadow-lg{
      box-shadow: 0 -20px 150px 0px rgba($primary, 0.65);
    }
    &.section-bg-dark{
      background-color: rgba($black, 0.35);
    }
}
@include color-mode(dark) {
    .section-landing {
      // background-image: $page-bg-img-dark;
      // background-size: cover;
      // background-repeat: no-repeat;
      &.section-bg-dark{
        background-color: rgba($black, 0.35);
      }
      &.no-bg{
        background-image: none;
      }
      &.bg-overlay{
        &::before{
          background-color: rgba($black, 0.35);
        }
      }
    }
  }
@include color-mode(light) {
  .section-landing {
    // background-image: $page-bg-img;
    // background-size: cover;
    // background-repeat: no-repeat;
    &.section-bg-dark{
      background-color: rgba($white, 0.10);
    }
    &.no-bg{
      background-image: none;
    }
    &.bg-overlay{
      &::before{
        background-color: rgba($black, 0.35);
      }
    }
  }
}