@include color-mode(light) {
    .top-market-trends{
      background-color: rgba(217, 217, 217, 0.1);
      border-color: rgba(218, 218, 218, 1);
      color: $dark;
    }
  }

@include color-mode(dark) {
}

.top-market-trends{
    background-color: rgba(217, 217, 217, 0.06);
    border-color: transparent;
    color: $light;
  }
  
  // Desktop mode
  @include media-breakpoint-up(lg) {
  
  }
  
  // Tablet & mobile modes
  @include media-breakpoint-down(lg) {
    
  }