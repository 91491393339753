.payment-logo-sec {
  border: 1px solid #dadada;
  background: #fbfbfb;
  border-radius: 10px;
  height: 141vh;
  padding: 1.6rem;
  margin: 1rem;
}

.inner-bttm ul {
  padding: 0;
}

.inner-bttm ul li {
  font: 500 1.375rem/2rem "TT Norms Pro";
  color: #6e6f77;
  padding: 0 0.6rem 0 0;
  cursor: pointer;
  list-style: none;
}
.payment-logo-sec {
  border: none;
  background: transparent;
  height: auto;
}

.inner-bttm ul li:nth-last-child(1) {
  font: 800 1.375rem/2rem "TT Norms Pro";
  // border-right: 2px solid #6e6f77;
}

.payment-main {
  padding: 2rem 0;
}

.inner_form.payment-main .form-group {
  margin-bottom: 1rem;
}

.form_1.payment-main .form-group p {
  padding-bottom: 0;
}

.inner_form.form_1 .form-group .payment-bx input {
  width: 15%;
  text-align: center;
}

.bg-lite.dark_theme .payment-main .form-group .form-control.StripeElement {
  background: #fff !important;
}

.payment-logo-sec {
  border: none;
  background: transparent;
  height: auto;
}

/* Payment dark page css closed */
.dark_theme .inner_form.form_1 .form-group input,
.dark_theme .form_1.inner_form .form-group .pay-select select,
.dark_theme .payment-main .form-group .form-control.StripeElement {
  padding: 0.75rem 0.55rem;
  color: #96979c;
  font: 400 0.875rem/1rem "TT Norms Pro";
  background: #151623 !important;
  border: 1px solid #37394d;
  border-radius: 4px;
}
