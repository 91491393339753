//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

//Fonts
$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-logo: 'Poppins', sans-serif;

//Font Size Base

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: 14px;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.35;

// Theme colors

//
// Custom Variable
//

$body-bg: #fff;
$white: #f1f1f1;
$black: #000000;

// Primary colors
$primary: #3699ff;
$primary-active: #187de4;
$primary-light: rgba(13, 154, 255, 0.1); // #e7f1fc;
$primary-light-dark: #0d3f70;
$primary-inverse: white;

// Success
$success: #06893d;
$success-active: #066b30;
$success-light: #d5e8df;
$success-light-dark: #033619;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #db0000;
$danger-active: #8b0000;
$danger-light: #f5d4d5;
$danger-light-dark: #312222;
$danger-inverse: #ffffff;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: #ffffff;

// Card Box Shadow
$card-box-shadow: 0px 10px 35px 0px rgba(56, 71, 109, 0.075);
$card-box-shadow-dark: none;
$card-border-radius: $border-radius-xl;
$card-bg: rgba(217, 217, 217, 0.1);
$card-border-color: rgba(218, 218, 218, 1);
$card-bg-dark: rgba(217, 217, 217, 0.06);
$card-border-color-dark: rgba(255, 255, 255, 0.14);
$card-border-width: 1px;
$card-px: 1.25rem;
$card-py: 1.25rem;

//Faq Accordion
$accordion-icon-width: 2.25rem;
$accordion-icon-color: $body-color;
$accordion-icon-active-color: $primary;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$accordion-icon-color}' viewBox='0 0 16 16><path fill-rule='evenodd d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2'/></svg>");
$accordion-button-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$accordion-icon-active-color}" className="bi bi-dash-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/></svg>');

$accordion-button-icon-dark: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$accordion-icon-color-dark}" className="bi bi-plus-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/></svg>');
$accordion-button-active-icon-dark: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$accordion-icon-active-color-dark}" className="bi bi-dash-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/></svg>');

// Border Radius

// Border Radius
$border-radius: 0.6rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1500px,
);

// Media breakpoints
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1440px,
);
