// common
.ag-header-cell {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding-left: 16px !important;
    padding-right: 16px !important;
}
.ag-root-wrapper {
  border: 0px !important;
  font-family: 'Poppins', sans-serif;
}
.ag-cell{
  padding-left: 14px !important;
  padding-right: 14px !important;
}

// Media query for smaller devices (adjust the max-width as needed)
@media (max-width: 767px) {
  .ag-header-cell {
    padding-left: 12px !important;
    padding-right: 12px !important;
    font-size: 0.9rem !important;
  }
}
@media (max-width: 320px) {
  .ag-header-cell {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 0.7rem !important;
  }
}

// dark
.ag-theme-alpine-dark {
  --ag-font-size: 11px !important;
  --ag-header-height: 30px;
  --ag-row-height: 30px;
  --ag-background-color: transparent !important;
  --ag-odd-row-background-color: transparent !important;
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-foreground-color: white;
  .ag-header {
    backdrop-filter: blur(10px);
    border: 0px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
  }
  .ag-menu {
    background-color: #121321 !important; 
    box-shadow: 0px 24px 84px rgba(0, 0, 0, 0.20) !important; 
    border-radius: 10px; 
    border: 1px solid rgba(255, 255, 255, 0.20) !important;
    overflow-y: visible;
  }

  .ag-center-cols-container{
    border-right: 1px solid #bdbdbd30;
  }
  
}
.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}
// .ag-icon-menu::before {
//   content: var(--ag-icon-font-code-pin, "\f114") !important;
// }

// light
.ag-theme-alpine {
  --ag-font-size: 11px !important;
  --ag-header-height: 30px;
  --ag-background-color: transparent !important;
  --ag-odd-row-background-color: transparent !important;
  --ag-foreground-color: black;
  .ag-header {
    backdrop-filter: blur(10px);
    border: 0px;
    border-radius: 4px;
    background: #eeeef0;
  }
  .ag-menu {
    background-color: white !important; 
    box-shadow: 0px 24px 84px rgba(0, 0, 0, 0.20) !important; 
    border-radius: 10px; 
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
  }

  .ag-center-cols-container{
    border-right: 1px solid #7676764a;
  }
}