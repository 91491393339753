/* poppins-100 - latin !Ankit/Rakesh */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('./poppins-v21-latin-100.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-100.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-100italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('./poppins-v21-latin-100italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-100italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('./poppins-v21-latin-200.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-200.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('./poppins-v21-latin-200italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-200italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./poppins-v21-latin-300.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-300.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('./poppins-v21-latin-300italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-300italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./poppins-v21-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-regular.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('./poppins-v21-latin-italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./poppins-v21-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-500.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('./poppins-v21-latin-500italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-500italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./poppins-v21-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-600.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('./poppins-v21-latin-600italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-600italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./poppins-v21-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-700.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('./poppins-v21-latin-700italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-700italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./poppins-v21-latin-800.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-800.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('./poppins-v21-latin-800italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-800italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./poppins-v21-latin-900.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-900.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('./poppins-v21-latin-900italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('./poppins-v21-latin-900italic.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
