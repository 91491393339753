//
// Page loader(used by the previous layout system)
//

// CSS3 Transitions only after page load(.page-loading class added to body tag and remove with JS on page load)
.page-loading * {
  transition: none !important;
}

// Base
.page-loader {
  background-color: var(--#{$prefix}body-bg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .card &{
    border-radius: 15px;
  }
  .page-loading & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
