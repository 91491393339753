//
// Content
//
main{
  min-height: calc(100vh - get($header-config, desktop, dashboard, height));
 // padding-top: get($header-config, desktop, default, height);
 &.main-landing{
    padding-top: get($header-config, desktop, default, height);
    min-height: calc(100vh - get($header-config, desktop, default, height));
    background-color: transparent;
    background-image: $landing-bg-img-dark;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
 }
 &.main-dashboard{
   position: relative;
  }
  &.main-auth{
    padding-top: get($header-config, desktop, default, height);
    //min-height: calc(100vh - get($header-config, desktop, default, height));
    min-height: calc(100vh);
  }
}
.app-main{
 // padding-left: get($aside-config, width, desktop);
  //padding-top: get($header-config, desktop, default, height);
  position: relative;
  transition: width get($aside-config, transition-speed) ease;
  min-height: calc(100vh - get($header-config, desktop, dashboard, height));
  height: calc(100vh - get($header-config, desktop, dashboard, height));
  max-height: calc(100vh - get($header-config, desktop, dashboard, height));
  overflow: hidden;
  @include media-breakpoint-down(xl) {
    height: get($header-config, small-desktop, default, height);
    min-height: calc(100vh - get($header-config, small-desktop, default, height));
  }
}

.app-content{
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: 0;
  }
  .app-main{
    //padding-left: get($aside-config, width, desktop);
    //padding-top: get($header-config, desktop, default, height);
    .menu-expanded &{
      //padding-left: get($aside-config, expended-width, desktop);
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content {
    // padding: get($content-spacing, tablet-and-mobile) 0;
  }
}
