//
// Root
//

// Light mode
@include color-mode(light) {
  // Page bg
  --#{$prefix}page-bg: #{$page-bg}; 

  .card-auth{
    background-color: rgba(217, 217, 217, 0.06); 
    border-color: rgba(217, 217, 217, 0.48);
  }
  .form-control, .form-select{
      background-color: white;
        border-color: rgba(190, 199, 245, 0.2);
        &::placeholder {
          color: rgba(150, 151, 156, 1);
          opacity: 1; /* Firefox */
        }
    }
  .app-aside{
    border-color: rgba(218, 218, 218, 1);
    .side-nav{
      .nav{
          .nav-link{
              color: $dark;
          }
        }
      }
  }

}

// Dark mode
@include color-mode(dark) {
  // Page bg
  --#{$prefix}page-bg: #{$page-bg-dark};

  .form-control, .form-select{
    border-color: rgba(190, 199, 245, 0.2);
            background-color: rgba(23, 25, 38, 0.6);
       &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
  }
  .app-aside{
    border-color: rgba(255, 255, 255, 0.1);
    .side-nav{
      .nav{
          .nav-link{
              color: $light;
          }
        }
      }
  }
  
}
