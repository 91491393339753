//
// Modal
//

.modal-rounded {
  border-radius: $modal-content-border-radius !important;
}
.modal-filter-management{
  .modal-content{
    background: #121321;
  }
}


@include color-mode(dark) {
  .modal-filter-management{
    .modal-content{
      background: #121321;
    }
  }
}
@include color-mode(light) {
  .modal-filter-management{
    .modal-content{
      background: white;
    }
  }
}