sign-pad-container{
    background-color: white;
    border: 1px solid;
    height: 160px;
}
.sign-pad{
    width: 100%;
    height: 100%;
    background-color: white;
    
}